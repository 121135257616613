import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import { useHistory } from "react-router-dom";
import "./User.css";
import { auth } from "../services/supabase";
import DashboardLayout from "../components/DashboardLayout";
import { Breadcrumb, Button } from "antd";

function User() {
  const history = useHistory();
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    auth.user().then((res) => {
      console.log("authed", res.data);
      setUser(res.data.user);
    });
  }, []);

  return (
    <DashboardLayout>
      <Breadcrumb>
        <Breadcrumb.Item>Smithy</Breadcrumb.Item>
        <Breadcrumb.Item>Apps</Breadcrumb.Item>
      </Breadcrumb>
      <h1>User: {user?.email ? user?.email : user?.phone}</h1>
      <Button
        type="primary"
        onClick={() =>
          auth.signOut().then(() => {
            history.replace("/login");
          })
        }
      >
        Sign Out
      </Button>
      <div style={{ height: 200 }}></div>
    </DashboardLayout>
  );
}

export default User;
