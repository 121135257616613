import React, { useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonButton,
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonItem,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import "./SignUp.css";
import { auth } from "../services/supabase";

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const history = useHistory();

  const handleSignUp = async () => {
    console.log("sign up");
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Sign Up</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="signup-page">
        <IonGrid style={{ height: "100%" }}>
          <IonRow
            style={{ height: "100%" }}
            className="ion-align-items-center ion-justify-content-center"
          >
            <IonCard style={{ width: 400 }}>
              <IonCardContent>
                <h1>Prompt Manager</h1>
                <h3>AI prompt CMS for non-developer humans</h3>
                <IonItem>
                  <IonInput
                    type="email"
                    placeholder="Email"
                    value={email}
                    onIonChange={(e) => setEmail(e.detail.value!)}
                  />
                </IonItem>
                <IonItem>
                  <IonInput
                    type="password"
                    placeholder="Password"
                    value={password}
                    onIonChange={(e) => setPassword(e.detail.value!)}
                  />
                </IonItem>
                <IonItem>
                  <IonInput
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onIonChange={(e) => setConfirmPassword(e.detail.value!)}
                  />
                </IonItem>
                <IonButton style={{ marginTop: 30 }} onClick={handleSignUp}>
                  Sign Up
                </IonButton>
              </IonCardContent>
            </IonCard>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}

export default SignUp;
