import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import { useHistory } from "react-router-dom";
import "./Home.css";
import { auth, db } from "../services/supabase";
import DashboardLayout from "../components/DashboardLayout";
import { Breadcrumb, Card } from "antd";
import {
  CheckCircleOutlined,
  CheckCircleFilled,
  ContainerOutlined,
  LinkOutlined,
  ShareAltOutlined,
  CodeOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import { Footer } from "antd/es/layout/layout";
import Input from "../components/Input";
import _, { set } from "lodash";
import openai from "../services/openai";

function Home() {
  const [user, setUser] = useState<any>(null);
  const [prompts, setPrompts] = useState<any>(null);
  const [selectedPrompt, setSelectedPrompt] = useState<any>(null);
  const [inputs, setInputs] = useState<any>(null);
  const [generatedPrompt, setGeneratedPrompt] = useState<any>(null);
  const [responses, setResponses] = useState<any>(null);
  const [selectedResponse, setSelectedResponse] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [search, setSearch] = useState<any>(null);
  const [isMobile, setIsMobile] = useState<any>(false);
  const [showResponses, setShowResponses] = useState<any>(true);

  function detectMob() {
    setIsMobile(window.innerWidth <= 800);
    setShowResponses(window.innerWidth >= 800);
  }

  useEffect(() => {
    auth.user().then((res) => {
      console.log("authed", res.data);
      setUser(res.data.user);
    });

    db.getPrompts().then((res) => {
      console.log("prompts", res.data);
      setPrompts(res.data);
    });
    detectMob();
  }, []);

  function AppCard(props: any) {
    return (
      <div style={{ padding: 5 }}>
        <div
          style={{
            width: "100%",
            backgroundColor: "#efefef",
            padding: 8,
            borderRadius: 10,
          }}
        >
          <div style={{ fontWeight: 600, fontSize: 16 }}>{props.title}</div>
          <div>{props.description}</div>
        </div>
      </div>
    );
  }

  function buildPrompt() {
    let x = selectedPrompt.prompt;
    inputs.map((input: any) => {
      x = x.replace(
        "{{" + input.label.toLowerCase().replace(" ", "_") + "}}",
        input.value
      );
    });
    const key = localStorage.getItem("openai-key");
    if (!key) {
      alert("Please add your openai key on the connections page");
      window.location.href = "/connections";
      return;
    }
    openai
      .complete(x, key)
      .then((res: any) => {
        setGeneratedPrompt(res.data.choices[0].text);
        setLoading(false);
        db.createResponse({
          text: res.data.choices[0].text,
          prompt_id: selectedPrompt.id,
          meta: inputs,
        }).then((res) => {
          console.log("response", res.data);
          db.getResponses(selectedPrompt.id).then((res) => {
            console.log("responses", res.data);
            setResponses(res.data);
          });
        });
      })
      .catch((err: any) => {
        console.log(err);
        alert("Error generating prompt");
        setLoading(false);
      });
  }

  function selectedPromptCard(p: any) {
    console.log("selectedPromptCard", p);
    const x: any = [];
    setSelectedPrompt(p);
    p.prompt.split("{{").map((word: any) => {
      if (word.includes("}}")) {
        console.log("word", word);
        console.log("word", word.split("}}")[0]);
        console.log("word", word.split("}}")[1]);
        x.push({
          type: "input",
          label: _.capitalize(word.split("}}")[0].replace("_", " ")),
          placeholder: _.capitalize(word.split("}}")[0].replace("_", " ")),
          value: "",
        });
        setInputs(x);
      }
    });
    db.getResponses(p.id).then((res) => {
      console.log("responses", res.data);
      setResponses(res.data);
    });
  }

  return (
    <DashboardLayout>
      <Breadcrumb>
        <Breadcrumb.Item>Smithy</Breadcrumb.Item>
        <Breadcrumb.Item>Apps</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ display: isMobile ? "block" : "flex", paddingTop: 20 }}>
        <div style={{ flex: 1 }}>
          <h2>Apps</h2>
          {!selectedPrompt ? (
            <>
              <div>
                <Input
                  title="Search"
                  placeholder="Search"
                  value={search}
                  onChange={(e: any) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
              {prompts &&
                prompts
                  .filter((x: any) => {
                    if (search) {
                      if (
                        x.title.toLowerCase().includes(search.toLowerCase())
                      ) {
                        return true;
                      }
                      return false;
                    }
                    return true;
                  })
                  .map((prompt: any) => (
                    <div
                      key={prompt.id}
                      onClick={() => {
                        selectedPromptCard(prompt);
                      }}
                    >
                      <AppCard
                        title={prompt.title}
                        description={prompt.description}
                      />
                    </div>
                  ))}
            </>
          ) : (
            <div style={{ position: "relative" }}>
              <div style={{ position: "absolute", top: -5, right: -5 }}>
                <IonButton
                  disabled={loading}
                  size="small"
                  shape="round"
                  color="dark"
                  onClick={() => {
                    setSelectedPrompt(null);
                    setGeneratedPrompt(null);
                    setResponses(null);
                  }}
                >
                  X
                </IonButton>
              </div>
              <div
                onClick={() => {
                  setSelectedResponse(null);
                  setGeneratedPrompt(null);
                }}
              >
                <AppCard
                  title={selectedPrompt.title}
                  description={selectedPrompt.description}
                />
              </div>
              {responses && (
                <div style={{ padding: 5 }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ paddingTop: 3 }}>
                      <h3
                        onClick={() => {
                          setShowResponses(!showResponses);
                        }}
                      >
                        Responses
                      </h3>
                    </div>{" "}
                    &nbsp;
                    <IonButton
                      size="small"
                      color={showResponses ? "primary" : "light"}
                      onClick={() => {
                        setShowResponses(!showResponses);
                      }}
                    >
                      👁
                    </IonButton>
                  </div>
                  {showResponses && (
                    <>
                      {responses.map((response: any) => (
                        <div
                          key={response.id}
                          style={{
                            backgroundColor: "#efefef",
                            padding: 10,
                            borderRadius: 10,
                            marginBottom: 10,
                          }}
                          onClick={() => {
                            setGeneratedPrompt(response.text);
                            response.meta
                              ? setSelectedResponse(response.meta)
                              : setSelectedResponse(null);
                          }}
                        >
                          <b>
                            {" "}
                            {response.meta &&
                              response.meta[0] &&
                              response.meta[0].value.slice(0, 100)}
                          </b>
                          <br />
                          {response.text &&
                            response.text
                              .slice(0, 175)
                              .replace(/(<([^>]+)>)/gi, "")}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div style={{ marginLeft: 10, flex: 3 }}>
          {selectedPrompt && (
            <>
              <h3>{selectedPrompt.title}</h3>
              {!generatedPrompt ? (
                <div
                  style={{
                    backgroundColor: "#efefef",
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  {inputs &&
                    inputs.map((input: any) => (
                      <div key={input.title} style={{ padding: 5 }}>
                        <div
                          style={{
                            fontWeight: 600,
                            paddingLeft: 4,
                          }}
                        >
                          {input.label}
                        </div>
                        <textarea
                          style={{
                            width: "100%",
                            borderRadius: 10,
                            padding: 5,
                            paddingTop: 8,
                          }}
                          rows={4}
                          title={input.label}
                          placeholder={"Enter " + input.placeholder}
                          onChange={(e: any) => {
                            const x = structuredClone(inputs);
                            x.map((i: any) => {
                              if (i.label === input.label) {
                                i.value = e.target.value;
                              }
                            });
                            setInputs(x);
                          }}
                        />
                      </div>
                    ))}
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <IonButton
                      disabled={
                        inputs.map((i: any) => i.value).includes("") || loading
                      }
                      shape="round"
                      color="dark"
                      onClick={() => {
                        setLoading(true);
                        buildPrompt();
                      }}
                    >
                      {loading ? "Loading..." : "Generate"}
                    </IonButton>
                  </div>
                </div>
              ) : (
                <>
                  {selectedResponse && (
                    <div
                      style={{
                        backgroundColor: "#efefef",
                        borderRadius: 10,
                        padding: 8,
                      }}
                    >
                      {selectedResponse.map((input: any) => (
                        <div key={input.title} style={{ padding: 5 }}>
                          <div
                            style={{
                              fontWeight: 600,
                              paddingLeft: 4,
                            }}
                          >
                            {input.label}
                          </div>
                          <div style={{}}>{input.value}</div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div
                    style={{ marginTop: 10 }}
                    dangerouslySetInnerHTML={{ __html: generatedPrompt }}
                  ></div>
                </>
              )}
            </>
          )}
        </div>
        <div style={{ height: 200 }}></div>
      </div>
    </DashboardLayout>
  );
}

export default Home;
