import React, { useEffect, useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import { useHistory } from 'react-router-dom';
import './Responses.css';
import { auth } from '../services/supabase';
import DashboardLayout from '../components/DashboardLayout';
import { Breadcrumb, Table } from 'antd';

function Responses(){
  const [user, setUser] = useState<any>(null);
  useEffect(() => {
    auth.user().then((res) => {
      console.log("authed",res.data);
      setUser(res.data.user);
    });
  }, []);

  const dataSource = [
    {
      key: '1',
      name: 'Mike',
      age: 32,
      address: '10 Downing Street',
    },
    {
      key: '2',
      name: 'John',
      age: 42,
      address: '10 Downing Street',
    },
  ];
  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
  ];

  return (
    <DashboardLayout>
       <Breadcrumb>
       <Breadcrumb.Item>Smithy</Breadcrumb.Item>
    <Breadcrumb.Item>Responses</Breadcrumb.Item>
  </Breadcrumb>
  <Table dataSource={dataSource} columns={columns} />

    </DashboardLayout>
  );
}


export default Responses;