import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonButton,
  IonGrid,
  IonRow,
  IonCard,
  IonCardContent,
  IonItem,
} from "@ionic/react";
import { auth } from "../services/supabase";
import { useHistory } from "react-router-dom";
import "./Login.css";
import { Button, Input } from "antd";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ReactCodeInput from "react-code-input";

function Login() {
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showCode, setShowCode] = useState(false);
  const history = useHistory();

  const handleLogin = async () => {
    try {
      auth.sendCode(phone).then((res: any) => {
        console.log("res", res);
        setShowCode(true);
      });
    } catch (error: any) {
      alert(error.message);
    }
  };

  const handleCode = async (code: any) => {
    if (code.length < 6) return;
    try {
      auth.verifyCode(phone, code).then((res: any) => {
        console.log("res", res);
        if (res !== null && res.data !== null && res.data.user !== null) {
          window.location.href = "/home";
        }
      });
    } catch (error: any) {
      alert(error.message);
    }
  };

  function isSignedIn() {
    auth.user().then((res) => {
      console.log("authed", res.data);
      if (res !== null && res.data !== null && res.data.user !== null) {
        history.push("/home");
        return true;
      } else {
        return false;
      }
    });
  }

  useEffect(() => {
    console.log("auth");
    isSignedIn();
  }, [history]);

  return (
    <IonPage>
      <IonContent className="login-page">
        <IonGrid style={{ height: "100%" }}>
          <IonRow
            style={{ height: "100%" }}
            className="ion-align-items-center ion-justify-content-center"
          >
            <IonCard style={{ width: 400 }}>
              <IonCardContent>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src="./assets/1.png"
                    alt="logo"
                    style={{ width: 200, marginBottom: 20 }}
                  />
                </div>
                <h3>Just another prompt hosting app. Currently invite only</h3>
                <form>
                  <div style={{ paddingTop: 10 }}>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={(e: any) => setPhone(e)}
                      defaultCountry="US"
                      maxLength={16}
                    />
                  </div>
                  {showCode ? (
                    <>
                      <div style={{ paddingTop: 10 }}>
                        <ReactCodeInput
                          type="number"
                          fields={6}
                          name={"code"}
                          inputMode={"tel"}
                          inputStyle={{
                            fontFamily: "monospace",
                            margin: "4px",
                            MozAppearance: "textfield",
                            width: "53px",
                            borderRadius: "8px",
                            fontSize: "34px",
                            height: "66px",
                            paddingLeft: "7px",
                            textAlign: "center",
                            backgroundColor: "black",
                            color: "white",
                            border: "1px solid #c4c4c4",
                          }}
                          onChange={(e: any) => {
                            handleCode(e);
                          }}
                        />
                        <div style={{ marginTop: 10, textAlign: "right" }}>
                          <IonButton
                            color="dark"
                            onClick={() => setShowCode(false)}
                            size="small"
                            shape="round"
                          >
                            Try Again
                          </IonButton>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>
                      <div style={{ textAlign: "right" }}>
                        <Button
                          style={{ marginTop: 30, textAlign: "right" }}
                          size="large"
                          shape="round"
                          color="dark"
                          onClick={handleLogin}
                        >
                          Send Code
                        </Button>
                      </div>
                    </div>
                  )}
                </form>
              </IonCardContent>
            </IonCard>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}

export default Login;
