import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import { useHistory } from "react-router-dom";
import "./Connections.css";
import { auth, db } from "../services/supabase";
import DashboardLayout from "../components/DashboardLayout";
import { Alert, Breadcrumb, Card, Checkbox } from "antd";
import { CheckCircleOutlined, CheckCircleFilled } from "@ant-design/icons";
import Input from "../components/Input";

function Connections() {
  const [key, setKey] = useState<any>(null);
  const [model, setModel] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [keyTouched, setKeyTouched] = useState<any>(false);
  const [connections, setConnections] = useState<any>([]);
  useEffect(() => {
    auth.user().then((res) => {
      console.log("authed", res.data);
      setUser(res.data.user);
    });
    if (localStorage.getItem("openai-key")) {
      setKey(localStorage.getItem("openai-key"));
    }
    if (localStorage.getItem("openai-model")) {
      setModel(localStorage.getItem("openai-model"));
    }
    db.getConnections().then((cres) => {
      console.log("connections", cres.data);
      setConnections(cres.data);
      if (
        !localStorage.getItem("openai-key") &&
        cres &&
        cres.data &&
        cres.data.length > 0
      ) {
        if (cres.data.length > 0) {
          setKey(cres.data[0].key);
        }
      }
    });
  }, []);

  function save() {
    localStorage.setItem("openai-key", key);
    if (model) localStorage.setItem("openai-model", model);
    const now = new Date();
    db.createConnection({ key, set_at: now }).then((res) => {
      console.log("saved", res.data);
      alert("Saved");
      window.location.reload();
    });
  }

  return (
    <DashboardLayout>
      <Breadcrumb>
        <Breadcrumb.Item>Smithy</Breadcrumb.Item>
        <Breadcrumb.Item>Connection</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ height: 20 }}></div>
      <h2>Connection Settings</h2>
      <p>
        We use your open api key to make all of the calls so that we can offer
        this for free.
      </p>{" "}
      <div style={{ marginBottom: 10 }}>
        💲 OpenAI requires a paid subscription now
      </div>
      <p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://platform.openai.com/account/api-keys"
        >
          Get your key here
        </a>
      </p>
      <Input
        title="Open API Key"
        placeholder={key ? key : "Key"}
        value={key}
        onChange={(e: any) => {
          setKeyTouched(true);
          setKey(e.target.value);
        }}
      />
      <div>
        <div style={{ fontWeight: 600, marginTop: 8 }}>Set Model</div>
        <select
          value={model}
          style={{
            width: "100%",
            maxWidth: 500,
            height: 40,
            borderRadius: 10,
            border: "1px solid #ccc",
            marginBottom: 10,
            backgroundColor: "#fff",
          }}
          onChange={(e) => {
            setModel(e.target.value);
          }}
        >
          <option>text-davinci-003</option>
          <option>text-davinci-002</option>
          <option>text-davinci-001</option>
          <option>davinci-instruct-beta</option>
          <option>davinci</option>
          <option>text-ada-001</option>
          <option>ada</option>
          <option>text-babbage-001</option>
          <option>babbage</option>
          <option>text-curie-001</option>
          <option>curie</option>
        </select>
      </div>
      {key && (
        <IonButton
          shape="round"
          color="dark"
          disabled={!keyTouched}
          onClick={() => {
            console.log("save");
            save();
          }}
        >
          Save
        </IonButton>
      )}
      <div>
        <div style={{ fontWeight: 600, marginTop: 8 }}>Your API Keys</div>
        <div style={{ marginTop: 8 }}>
          {connections &&
            connections.map((connection: any) => {
              return (
                <div
                  key={connection.id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      fontSize: 16,
                      color: "#667",
                      fontWeight: 600,
                      display: "flex",
                    }}
                  >
                    <IonButton
                      size="small"
                      color={
                        localStorage.getItem("openai-key") == connection.key
                          ? "success"
                          : "light"
                      }
                      onClick={() => {
                        localStorage.setItem("openai-key", connection.key);
                        db.updateConnection(connection.id, {
                          set_at: new Date(),
                        }).then((res) => {
                          window.location.reload();
                        });
                      }}
                    >
                      {localStorage.getItem("openai-key") == connection.key
                        ? "✔️"
                        : "💾"}
                    </IonButton>
                    &nbsp;
                    <IonButton
                      size="small"
                      color="light"
                      onClick={() => {
                        db.deleteConnection(connection.id).then((res) => {
                          console.log("deleted", res.data);
                          window.location.reload();
                        });
                      }}
                    >
                      🗑
                    </IonButton>
                    &nbsp; &nbsp;
                    <div style={{ paddingTop: 7 }}>
                      {connection.key.substring(0, 3)}...{" "}
                      {connection.key.slice(-4)}
                    </div>
                    &nbsp;
                  </div>
                  <div></div>
                </div>
              );
            })}
        </div>
        <div style={{ height: 200 }}></div>
      </div>
    </DashboardLayout>
  );
}

export default Connections;
