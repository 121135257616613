import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ExploreContainer from "./ExploreContainer";
import { useHistory } from "react-router-dom";
import "./DashboardLayout.css";
import { auth } from "../services/supabase";
import { Button, Layout, Menu } from "antd";
import type { MenuProps } from "antd";
import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  PieChartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Content, Footer, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import { platform } from "os";

function DashboardLayout(props: any) {
  const history = useHistory();
  const [user, setUser] = useState<any>(null);
  const [isMobile, setIsMobile] = useState<any>(false);

  function detectMob() {
    setIsMobile(window.innerWidth <= 800);
  }

  useEffect(() => {
    const checkSession = async () => {
      try {
        const authUser = await auth.user();
        setUser(authUser.data.user);
        console.log(authUser);
        if (authUser.data.user === null) {
          history.push("/login");
        }
      } catch (error) {
        history.push("/login");
      }
    };
    detectMob();
    checkSession();
  }, [history]);

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem("Apps", "apps", <AppstoreOutlined />),
    getItem("My Prompts", "prompts", <ContainerOutlined />),
    getItem("Connections", "connections", <DesktopOutlined />),
    getItem("User", "user", <UserOutlined />),

    // getItem('Apps', 'sub1', <DesktopOutlined />, [
    //   getItem('Option 5', '5'),
    //   getItem('Option 6', '6'),
    //   getItem('Option 7', '7'),
    //   getItem('Option 8', '8'),
    // ]),
  ];

  return (
    <IonPage>
      {user && user.id && (
        <Layout>
          {isMobile ? (
            <div
              style={{
                display: "flex",
                position: "fixed",
                bottom: 40,
                left: 0,
                width: "calc(100% - 12px )",
                borderRadius: 8,
                margin: 6,
                padding: 5,
                zIndex: 1000,
                backgroundColor: "#efefef",
              }}
            >
              {items.map((item: any) => (
                <div
                  style={{
                    flex: 1,
                    backgroundColor: "#fff",
                    padding: 2,
                    paddingBottom: 10,
                    paddingTop: 10,
                    fontSize: 12,
                    fontWeight: 600,
                    margin: 2,
                    borderRadius: 8,
                    textAlign: "center",
                  }}
                  key={item.key}
                  onClick={() => {
                    window.location.href = `/${item.key}`;
                  }}
                >
                  {item.label}
                </div>
              ))}
            </div>
          ) : (
            <Sider theme="light" collapsed={collapsed}>
              <div style={{ width: "100%", padding: 10, textAlign: "center" }}>
                <img
                  src="./assets/2.png"
                  alt="logo"
                  style={{ width: "100%", maxWidth: 120, marginBottom: 20 }}
                />
              </div>
              <Menu
                defaultSelectedKeys={[
                  window.location.pathname.replace("/", ""),
                ]}
                defaultOpenKeys={[window.location.pathname.replace("/", "")]}
                mode="inline"
                theme="light"
                inlineCollapsed={collapsed}
                items={items}
                onSelect={(item) => {
                  console.log(item);

                  history.replace(`/${item.key}`);
                }}
              />
            </Sider>
          )}
          <Content style={{ backgroundColor: "#fff", overflow: "scroll" }}>
            <Header style={{ backgroundColor: "#fff" }}>
              {isMobile ? null : (
                <div style={{ width: "100%", height: 100 }}>
                  <Button
                    slot="start"
                    onClick={toggleCollapsed}
                    style={{ marginLeft: -32 }}
                  >
                    {React.createElement(
                      collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
                    )}
                  </Button>
                </div>
              )}
              {isMobile && (
                <div style={{ textAlign: "center", paddingTop: 12 }}>
                  <img
                    src="./assets/1.png"
                    alt="logo"
                    style={{ width: "auto", maxHeight: 60 }}
                  />
                </div>
              )}
            </Header>{" "}
            <div style={{ padding: 22 }}>
              {user && user.id && props.children}
            </div>
          </Content>
        </Layout>
      )}
    </IonPage>
  );
}

export default DashboardLayout;
