import React from "react";
import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./Home/Home";
import Login from "./Login/Login";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import SignUp from "./Login/SignUp";

import "antd/dist/reset.css";
import Responses from "./Responses/Responses";
import Connections from "./Connections/Connections";
import User from "./User/User";
import Prompts from "./Prompts/Prompts";

setupIonicReact({ animated: false, swipeBackEnabled: false });

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/apps">
          <Home />
        </Route>
        <Route exact path="/responses">
          <Responses />
        </Route>
        <Route exact path="/connections">
          <Connections />
        </Route>
        <Route exact path="/user">
          <User />
        </Route>
        <Route exact path="/prompts">
          <Prompts />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/signup" component={SignUp} />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
