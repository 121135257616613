import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ExploreContainer from "../components/ExploreContainer";
import { useHistory } from "react-router-dom";
import "./Prompts.css";
import { auth, db } from "../services/supabase";
import DashboardLayout from "../components/DashboardLayout";
import { Alert, Breadcrumb, Card, Checkbox } from "antd";
import { CheckCircleOutlined, CheckCircleFilled } from "@ant-design/icons";
import Input from "../components/Input";
import TextArea from "../components/TextArea";

function Prompts() {
  const [key, setKey] = useState<any>(null);
  const [model, setModel] = useState<any>(null);
  const [user, setUser] = useState<any>(null);
  const [keyTouched, setKeyTouched] = useState<any>(false);
  const [prompts, setPrompts] = useState<any>([]);
  const [showNewPrompt, setShowNewPrompt] = useState<any>(false);
  const [newTitle, setNewTitle] = useState<any>(null);
  const [newPrompt, setNewPrompt] = useState<any>(null);
  const [newDescription, setNewDescription] = useState<any>(null);
  const [isEditing, setIsEditing] = useState<any>(false);
  const [editId, setEditId] = useState<any>(null);
  const [isMobile, setIsMobile] = useState<any>(false);

  function detectMob() {
    setIsMobile(window.innerWidth <= 800);
  }
  useEffect(() => {
    db.getMyPrompts().then((cres) => {
      console.log("prompts", cres.data);
      setPrompts(cres.data);
    });

    detectMob();
  }, []);
  return (
    <DashboardLayout>
      <Breadcrumb>
        <Breadcrumb.Item>Smithy</Breadcrumb.Item>
        <Breadcrumb.Item>Connection</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ height: 20 }}></div>
      <h2>My Prompts</h2>

      <IonButton
        size="small"
        shape="round"
        onClick={() => {
          setShowNewPrompt(!showNewPrompt);
          setNewTitle(null);
          setNewDescription(null);
          setNewPrompt(null);
          if (showNewPrompt) {
            setIsEditing(false);
          }
        }}
      >
        {showNewPrompt ? "Close" : "New"}
      </IonButton>
      {showNewPrompt && (
        <Card style={{ width: "100%", backgroundColor: "#efefef" }}>
          <Input
            title="Title"
            label="Title"
            value={newTitle}
            onChange={(e: any) => {
              setNewTitle(e.target.value);
            }}
          />
          <Input
            title="Description"
            label="Description"
            value={newDescription}
            onChange={(e: any) => {
              setNewDescription(e.target.value);
            }}
          />
          <div
            style={{
              marginTop: 10,
              padding: 6,
              fontSize: "small",
              backgroundColor: "#ddd",
              width: "fit-content",
              borderRadius: 6,
            }}
          >
            ℹ Use double braces to indicate a variable. For example, if you want
            to use the variable &#34;name&#34;, you would write
            &#34;&#123;&#123;name&#125;&#125;&#34;.
          </div>
          <div
            style={{
              marginTop: 10,
              padding: 6,
              fontSize: "small",
              backgroundColor: "#ddd",
              width: "fit-content",
              borderRadius: 6,
            }}
          >
            ℹ To get better formatted results, instruct your prompt to format
            the respond with basic HTML tags.
          </div>
          <TextArea
            title="Prompt"
            label="Prompt"
            value={newPrompt}
            onChange={(e: any) => {
              setNewPrompt(e.target.value);
            }}
          />
          <IonButton
            disabled={!newTitle || !newPrompt}
            onClick={() => {
              if (isEditing) {
                db.updatePrompt(editId, {
                  id: editId,
                  title: newTitle,
                  description: newDescription,
                  prompt: newPrompt,
                }).then((res) => {
                  window.location.reload();
                });
                return;
              }

              db.createPrompt({
                title: newTitle,
                prompt: newPrompt,
                description: newDescription,
              }).then((res) => {
                window.location.reload();
              });
            }}
            size="small"
            shape="round"
          >
            Save
          </IonButton>
        </Card>
      )}
      <div style={{ width: "100%" }}>
        {prompts &&
          prompts.length > 0 &&
          prompts.map((p: any) => {
            return (
              <Card
                key={p.id}
                title={p.title}
                extra={
                  <>
                    <IonButton
                      size="small"
                      color={p.public ? "success" : "light"}
                      onClick={() => {
                        db.makePublicPrompt(p.id, !p.public).then((res) => {
                          window.location.reload();
                        });
                      }}
                    >
                      {p.public ? "👁" : "👁"}
                    </IonButton>
                    <IonButton
                      size="small"
                      color="light"
                      onClick={() => {
                        setShowNewPrompt(true);
                        setNewTitle(p.title);
                        setNewDescription(p.description);
                        setNewPrompt(p.prompt);
                        setIsEditing(true);
                        setEditId(p.id);
                      }}
                    >
                      🛠
                    </IonButton>
                    <IonButton
                      size="small"
                      color="dark"
                      onClick={() => {
                        db.deletePrompt(p.id).then((res) => {
                          window.location.reload();
                        });
                      }}
                    >
                      🗑
                    </IonButton>
                  </>
                }
                style={{
                  width: isMobile ? "calc(100% - 20px)" : "30%",
                  backgroundColor: "#efefef",
                  margin: 10,
                  display: "inline-block",
                  verticalAlign: "top",
                  height: 220,
                  overflow: "hidden",
                }}
              >
                <div style={{ fontSize: "small", fontWeight: "bold" }}>
                  {p.description}
                </div>
                <div>{p.prompt.substring(0, 200)}</div>{" "}
                {!p.public && (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      textAlign: "right",
                    }}
                  >
                    not public
                  </div>
                )}
              </Card>
            );
          })}
        <div style={{ height: 200 }}></div>
      </div>
    </DashboardLayout>
  );
}

export default Prompts;
