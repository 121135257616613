import axios from "axios";

let model = "text-davinci-003";

const openai = {
  async complete(prompt: string, key: string) {
    if (localStorage.getItem("openai-model") != null) {
      model = localStorage.getItem("openai-model")!;
    }

    return await axios.post(
      "https://api.openai.com/v1/completions",
      {
        prompt: prompt,
        model: model,
        max_tokens: 1000,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      }
    );
  },
};
export default openai;
