import React from "react";

interface Props {
  title: string;
  placeholder: string;
  onChange: (e: any) => void;
  value?: string;
}

export default function Input(props: any) {
  return (
    <>
      <div style={{ marginTop: 4, paddingLeft: 4, fontWeight: 600 }}>
        {props.title}
      </div>
      <div style={{ marginBottom: 4 }}>
        <input
          value={props.value}
          style={{
            maxWidth: 500,
            width: "100%",
            border: "1px solid #ccc",
            borderRadius: 10,
            padding: 10,
            height: 40,
          }}
          placeholder={props.placeholder}
          onChange={props.onChange}
        />
      </div>
    </>
  );
}
