import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://nkfgxoqjavbmnqnjmyig.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im5rZmd4b3FqYXZibW5xbmpteWlnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODU3NTE0NjMsImV4cCI6MjAwMTMyNzQ2M30.jD6ybTiRYDejMXLUIVM5IwTndAdK1h7Thx2vvdYMxRs";

const supabase = createClient(supabaseUrl, supabaseKey);

const auth = {
  async sendCode(phone: string) {
    const { error } = await supabase.auth.signInWithOtp({
      phone,
    });

    if (error) {
      alert(error.message);
    }
  },
  async verifyCode(phone: string, code: string) {
    return await supabase.auth.verifyOtp({
      phone,
      token: code,
      type: "sms",
    });
  },
  async user() {
    return supabase.auth.getUser();
  },
  async signOut() {
    return supabase.auth.signOut();
  },
};

const db = {
  getPages: async () => {
    return supabase.from("page").select("*").order("id", { ascending: true });
  },
  getPage: async (id: string) => {
    return supabase.from("page").select("*").eq("id", id).single();
  },
  createPage: async (data: any) => {
    return supabase.from("page").insert([data]);
  },
  runFunction: async (name: string, params: any) => {
    return supabase.rpc(name, params);
  },
  getPrompts: async () => {
    return supabase
      .from("prompt")
      .select("*")
      .eq("public", true)
      .order("id", { ascending: true });
  },
  getMyPrompts: async () => {
    const user = await auth.user();
    return supabase
      .from("prompt")
      .select("*")
      .eq("created_by", user.data.user?.id)
      .order("id", { ascending: true });
  },
  createPrompt: async (data: any) => {
    const user = await auth.user();
    data.created_by = user.data.user?.id;
    return supabase.from("prompt").insert([data]);
  },
  updatePrompt: async (id: number, data: any) => {
    return supabase.from("prompt").update(data).eq("id", id);
  },
  makePublicPrompt: async (id: number, publish: boolean) => {
    return supabase.from("prompt").update({ public: publish }).eq("id", id);
  },

  deletePrompt: async (id: number) => {
    return supabase.from("prompt").delete().eq("id", id);
  },

  createResponse: async (data: any) => {
    const user = await auth.user();
    data.created_by = user.data.user?.id;
    return supabase.from("response").insert([data]);
  },
  getResponses: async (prompt_id: number) => {
    return supabase
      .from("response")
      .select("*")
      .eq("prompt_id", prompt_id)
      .order("id", { ascending: true });
  },
  getConnections: async () => {
    return supabase
      .from("userConnection")
      .select("*")
      .order("set_at", { ascending: false });
  },
  createConnection: async (data: any) => {
    const user = await auth.user();
    data.user_id = user.data.user?.id;
    return supabase.from("userConnection").insert([data]);
  },
  deleteConnection: async (id: number) => {
    return supabase.from("userConnection").delete().eq("id", id);
  },
  updateConnection: async (id: number, data: any) => {
    return supabase.from("userConnection").update(data).eq("id", id);
  },
};

export { auth, db };
